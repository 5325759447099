import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class MediaPage extends React.Component {
  render() {
    const { data } = this.props
    const projects = data.allProjectsJson.edges
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout title={siteTitle}>
        <SEO title="Projects" />
  
        <article className="post-content page-template no-image">
          <header className="post-content-header">
            <h1 className="post-content-title">Projects</h1>
            <p className="post-content-excerpt">I'm always working on something exciting. Here's a select few of my projects:</p>
          </header>

          <div className="post-content-body">
            <div className="project-container">
              {projects.map(({ node }) => {
                
                return(
                  <div className="post-card with-image"
                    style={node.image && { backgroundImage: `url(${ node.image.childImageSharp.fluid.src })` }}>
                      
                      <div className="post-card-content">
                        <a className="project-link" href={node.link} rel="noopener noreferrer" target="_blank">
                          {node.name}
                        </a>

                        <div className="post-card-body">
                          {node.description}
                        </div>
                        
                        <a href={node.link} className="post-card-link post-card-readmore" rel="noopener noreferrer" target="_blank">View project</a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allProjectsJson {
      edges {
        node {
          name
          link
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={pageQuery}
    render={data => (
      <MediaPage data={data} {...props} />
    )}
  />
)